<template>
  <div>
    <div class="header">
      <div class="inlayer">
        <div class="header-content container">
          <div class="header-left">
            <p>Mini Pupper</p>
          </div>
          <div class="header-right">
            <p>{{ this.$t("robot.math") }}</p>
            <p>{{ this.$t("robot.identify") }}</p>
            <img src="../../assets/robotDog/robot_img.d43e9fb1.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="content container">
      <div class="theory">
        <ul class="expand">
          <li>
            <p>{{ this.$t("robot.robotDog") }}</p>
          </li>
          <li>
            <img
              width="100%"
              src="../../assets/robotDog/robot_gif1.5d281fbc.gif"
              alt=""
            />
            <p>{{ this.$t("robot.robotDog") }}</p>
          </li>
          <li>
            <img
              width="100%"
              src="@/assets/robotDog/robot_gif2.3955ce63.gif"
              alt=""
            />
            <p>{{ this.$t("robot.robotDog") }}</p>
          </li>
          <li>
            <img
              width="100%"
              src="@/assets/robotDog/robot_gif3.fb9d8d3e.gif"
              alt=""
            />
            <p>{{ this.$t("robot.robotDog") }}</p>
          </li>
          <li>
            <img
              width="100%"
              src="@/assets/robotDog/robot_gif4.000368b2.gif"
              alt=""
            />
            <!-- <p>{{ this.$t("robot.robotDog") }}</p> -->
          </li>
        </ul>

        <ul class="intro">
          <li>
            <p>{{ this.$t("robot.foot") }}</p>
          </li>
          <li>
            <div>
              <p>{{ this.$t("robot.eye") }}</p>
              <p>{{ this.$t("robot.gesture") }}</p>
            </div>
          </li>
        </ul>

        <div class="title">
          <p>{{ this.$t("robot.OpenSource") }}</p>
        </div>
        <!-- support -->
        <img
          class="support"
          width="100%"
          src="../../assets/robotDog/support@2x.png"
          alt=""
        />
        <div class="title">
          <p>{{ this.$t("robot.person") }}</p>
          <p class="t-details">{{ this.$t('robot.imagine') }}</p>
        </div>
        <!-- structure -->
        <img
          class="structure"
          width="100%"
          src="@/assets/robotDog/structure@2x.png"
          alt=""
        />
        <!-- manual -->
        <img width="56.666%" src="@/assets/robotDog/manual@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
    
<style lang="less" scoped>
.header {
  background: #f2f2f2;
  .inlayer {
    background: url("../../assets/robotDog/banner.png") no-repeat center;

    .header-content {
      height: 933px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-left {
        align-self: start;
        p {
          margin-top: 57px;
          font-size: 26px;
          font-family: "Meiryooo";
          font-weight: bold;
          line-height: 39px;
          color: #0870d1;
        }
      }
      .header-right {
        width: 495px;
        float: right;
        p:first-child {
          font-size: 60px;
          font-family: "Meiryooo";
          font-weight: bold;
          line-height: 90px;
          color: #000000;
        }
        p:nth-child(2) {
          margin: 12px 0 27px 0;
          font-size: 18px;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 27px;
          color: #333333;
        }
        img {
          margin-left: -20px;
        }
        .clear {
          width: 100%;
          clear: both;
        }
      }
    }
  }
}
.content {
  .theory {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0 200px 0;
    .title {
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;
      p {
        font-family: "Meiryooo";
        font-weight: bold;
        font-size: 50px;
        line-height: 66px;
      }
      .t-details {
        font-size: 20px;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 30px;
        color: #0064e6;
        margin-top: 30px;
      }
    }
    .support {
      margin-bottom: 160px;
    }
    .structure {
      margin-bottom: 80px;
    }
  }
  .expand {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 100px;
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      img {
        margin-bottom: 60px;
      }
      p {
        font-size: 44px;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 59px;
        color: #000000;
      }
    }
    li:first-child {
      img {
        margin-bottom: 106px;
      }
    }
    li:last-child {
      margin: 0;
    }
  }
  .intro {
    width: 100%;
    margin-bottom: 160px;
    li {
      height: 600px;
      margin-top: 40px;
      display: flex;
      align-items: center;
    }
    li:first-child {
      background: url("../../assets/robotDog/1@2x.png") no-repeat center;
      background-size: cover;
      justify-content: flex-end;
      p {
        width: 500px;
        font-size: 50px;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 66px;
        margin-right: 60px;
      }
    }
    li:last-child {
      background: url("../../assets/robotDog/2@2x.png") no-repeat center;
      background-size: cover;
      justify-content: flex-start;
      div {
        margin-left: 80px;
        p:first-child {
          font-size: 50px;
          line-height: 66px;
          margin-bottom: 30px;
          font-family: "Meiryooo";
          font-weight: bold;
        }
        p:last-child {
          font-size: 20px;
          line-height: 30px;
          color: #0064e6;
          font-family: "Meiryooo";
          font-weight: bold;
        }
      }
    }
  }
}
</style>
    
<template>
  <div>
    <div class="header">
      <p>Mini Pupper</p>
      <div>
        <h1>{{ this.$t("robot.math") }}</h1>
        <p>{{ this.$t("robot.identify") }}</p>
      </div>
    </div>

    <ul class="model">
      <li>
        <h1>{{ this.$t("robot.robotDog") }}</h1>
        <img
          width="100%"
          src="@/assets/robotDog/robot_gif1.5d281fbc.gif"
          alt=""
        />
      </li>
      <li>
        <h1>{{ this.$t("robot.robotDog") }}</h1>
        <img
          width="100%"
          src="@/assets/robotDog/robot_gif2.3955ce63.gif"
          alt=""
        />
      </li>
      <li>
        <h1>{{ this.$t("robot.robotDog") }}</h1>
        <img
          width="100%"
          src="@/assets/robotDog/robot_gif3.fb9d8d3e.gif"
          alt=""
        />
      </li>
      <li>
        <h1>{{ this.$t("robot.robotDog") }}</h1>
        <img
          width="100%"
          src="@/assets/robotDog/robot_gif4.000368b2.gif"
          alt=""
        />
      </li>
    </ul>

    <ul class="content">
      <li>
        <h1>{{ this.$t("robot.foot") }}</h1>
      </li>
      <li>
        <h1>{{ this.$t("robot.eye") }}</h1>
        <p>{{ this.$t("robot.gesture") }}</p>
      </li>
    </ul>

    <div class="settings">
      <h1>{{ this.$t("robot.OpenSource") }}</h1>
      <img width="100%" src="../../assets/mobileRobotDog/one.png" alt="" />
      <h3>{{ this.$t("robot.person") }}</h3>
      <p>{{ this.$t("robot.imagine") }}</p>
      <div class="theory">
        <img width="100%" src="../../assets/mobileRobotDog/two.png" alt="" />
      </div>
      <img width="100%" src="../../assets/mobileRobotDog/three.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.header {
  height: 56vw;
  background: url("../../assets/mobileRobotDog/header.png") no-repeat center;
  background-size: cover;
  padding: 0 5.3333vw;
  display: flex;
  justify-content: space-between;
  p:first-child {
    margin-top: 4.2667vw;
    font-size: 3.2vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 4.8vw;
    color: #0870d1;
  }
  div {
    width: 41.6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-family: "Meiryooo";
    }
    p:first-child {
      font-size: 5.3333vw;
      font-weight: bold;
      line-height: 8vw;
      color: #000000;
    }
    p:nth-child(2) {
      font-size: 3.2vw;
      font-weight: 400;
      line-height: 4.8vw;
      color: #333333;
      margin: 2.1333vw 0 2.6667vw 0;
    }
  }
}

.content {
  padding: 0 4.2667vw;
  overflow: hidden;
  li {
    margin-top: 3.2vw;
    width: 100%;
    height: 45.8667vw;
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    h1 {
      padding: 0 2.6667vw;
      font-size: 4.2667vw;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 5.8667vw;
      color: #000000;
    }
    p {
      margin: 0 2.6667vw;
      font-size: 3.2vw;
      font-family: "Meiryooo";
      font-weight: 500;
      line-height: 6.9333vw;
      color: #0064e6;
      width: 50.6667vw;
    }
  }
  li:first-child {
    background: url("../../assets/mobileRobotDog/content1.png") no-repeat center;
    h1 {
      width: 100%;
      box-sizing: border-box;
      text-align: right;
    }
  }
  li:last-child {
    background: url("../../assets/mobileRobotDog/content2.png") no-repeat center;
    h1 {
      margin-bottom: 3.4667vw;
    }
  }
}
.settings {
  margin-top: 10.6667vw;
  h1 {
    font-size: 3.7333vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 5.3333vw;
    color: #000000;
    text-align: center;
  }
  // h2 {
  //   font-size: 3.2vw;
  //   font-family: "Meiryooo";
  //   font-weight: 500;
  //   line-height: 4.5333vw;
  //   color: #0870d1;
  //   text-align: center;
  //   margin: 0 4.2667vw;
  // }

  img {
    vertical-align: bottom;
  }
  img:nth-child(2) {
    margin-bottom: 10.6667vw;
  }

  h3 {
    font-family: "Meiryooo";
    font-size: 4.2667vw;
    font-weight: bold;
    line-height: 5.8667vw;
    color: #000000;
    text-align: center;
    padding: 0 4.2667vw;
  }
  p {
    margin: 3.2vw 0 3.2vw 0;
    font-size: 3.2vw;
    font-family: "Meiryooo";
    font-weight: 500;
    line-height: 4.5333vw;
    color: #0870d1;
    text-align: center;
    padding: 0 4.2667vw;
  }

  .theory {
    img {
      vertical-align: bottom;
    }
    img:first-child {
      margin-bottom: 10.6667vw;
    }
  }
  img:last-child {
    margin-bottom: 8vw;
  }
}
.model {
  padding: 0 4.2667vw;
  margin-bottom: 4.2667vw;
  h1 {
    font-size: 3.7333vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 5.0667vw;
    color: #000000;
    text-align: center;
    padding: 5.3333vw 0 3.2vw 0;
  }
  img {
    vertical-align: bottom;
  }
}
</style>